import { MdMoreHoriz, MdOpenInNew } from 'react-icons/md';
import { Menu, MenuItem } from '@material-ui/core';
import { toast } from 'react-toastify';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import React, { useContext, useState } from 'react';
import dayjs from 'dayjs';
import DatabaseContext from '../../contexts/DatabaseContext';
import ModalContext from '../../contexts/ModalContext';
import * as styles from './ResumePreview.module.css';

const menuToggleDataTestIdPrefix = 'resume-preview-menu-toggle-';

const ResumePreview = ({
  resume,
  showDuplicateMenu = true,
  showRenameMenu = true,
  showDeleteMenu = true,
  styleType = 'user',
}) => {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const { emitter, events } = useContext(ModalContext);
  const { duplicateResume, deleteResume } = useContext(DatabaseContext);

  const handleOpen = () => {
    window.open(`/app/builder/${resume.id}`, '_blank');
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDuplicate = () => {
    duplicateResume(resume);
    setAnchorEl(null);
  };

  const handleRename = () => {
    emitter.emit(events.CREATE_RESUME_MODAL, resume);
    setAnchorEl(null);
  };

  const handleDelete = () => {
    deleteResume(resume.id);
    toast(t('dashboard.toasts.deleted', { name: resume.name }));
    setAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={styles.resume}>
      <div
        className={cx(
          styleType === 'user' ? styles.backdrop : styles.backdropAdmin,
        )}
      >
        <img src={resume.profile.photograph} alt={resume.name} />
      </div>
      <div
        className={cx(styleType === 'user' ? styles.page : styles.pageAdmin)}
      >
        <MdOpenInNew
          color="#fff"
          size="48"
          className="cursor-pointer"
          onClick={handleOpen}
        />

        {(showDeleteMenu || showDuplicateMenu || showRenameMenu) && (
          <MdMoreHoriz
            data-testid={`${menuToggleDataTestIdPrefix}${resume.id}`}
            color="#fff"
            size="48"
            className="cursor-pointer"
            aria-haspopup="true"
            onClick={handleMenuClick}
          />
        )}

        <Menu
          keepMounted
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {showDuplicateMenu && (
            <MenuItem onClick={handleDuplicate}>
              {t('dashboard.buttons.duplicate')}
            </MenuItem>
          )}
          {showRenameMenu && (
            <MenuItem onClick={handleRename}>
              {t('dashboard.buttons.rename')}
            </MenuItem>
          )}
          {showDeleteMenu && (
            <MenuItem onClick={handleDelete}>
              <span className="text-red-600 font-medium">
                {t('shared.buttons.delete')}
              </span>
            </MenuItem>
          )}
        </Menu>
      </div>
      <div
        className={cx(styleType === 'user' ? styles.meta : styles.metaAdmin)}
      >
        <span>{resume.name}</span>
        {resume.updatedAt && (
          <span>
            {t('dashboard.lastUpdated', {
              timestamp: dayjs(resume.updatedAt)
                .locale(i18n.language.substr(0, 2))
                .fromNow(),
            })}
          </span>
        )}
      </div>
    </div>
  );
};

export default ResumePreview;

export { menuToggleDataTestIdPrefix };
